// CSS
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

// Javascript
import { setFormEvents, initForm } from "./modules/search-form";
import { setRangeParticipants } from "./modules/filters";
import { getReviewsInfos } from "./modules/reviews";
export { getLogos } from "./modules/logos";

(function ($) {
  $(function () {
    setFormEvents();
    setRangeParticipants();
    initForm();

    // Get Reviews Infos
    let request = {
      placeId: "ChIJjwbBPpNk5kcREMf3T0AYCmc",
      fields: ["rating", "user_ratings_total"],
    };

    let placesService = new google.maps.places.PlacesService(
      $("#reviewsInfos").get(0),
    );
    placesService.getDetails(request, getReviewsInfos);
  });
})(jQuery);
